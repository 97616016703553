import { graphql, useStaticQuery } from 'gatsby'
import { SocialLinksQuery } from 'graphqlTypes'
import React from 'react'

import facebookIcon from 'icons/facebook.svg'
import instagramIcon from 'icons/instagram.svg'
import linkedinIcon from 'icons/linkedin.svg'

import { FooterWrapper, SocialIcon, SocialIconList } from './Footer.styles'

const Footer = () => {
	const { strapi } = useStaticQuery(query) as NoUndefinedField<SocialLinksQuery>
	const { facebook, instagram, linkedin } = strapi.socialLink

	return (
		<FooterWrapper>
			<SocialIconList role="list">
				<li>
					<a target="_blank" rel="noopener" href={facebook}>
						<SocialIcon src={facebookIcon} />
						<span className="sr-only">Facebook</span>
					</a>
				</li>
				<li>
					<a target="_blank" rel="noopener" href={instagram}>
						<SocialIcon src={instagramIcon} />
						<span className="sr-only">Instagram</span>
					</a>
				</li>
				<li>
					<a target="_blank" rel="noopener" href={linkedin}>
						<SocialIcon src={linkedinIcon} />
						<span className="sr-only">Linkedin</span>
					</a>
				</li>
			</SocialIconList>
		</FooterWrapper>
	)
}

const query = graphql`
	query SocialLinks {
		strapi {
			socialLink {
				facebook
				instagram
				linkedin
			}
		}
	}
`

export default Footer
