import { styled } from '@linaria/react'

export const Label = styled.label`
	display: flex;
	flex-direction: column;
`

export const TextArea = styled.textarea`
	margin-top: 10px;
	padding: 10px 12px;
	border-radius: 8px;
	border: 1px solid var(--color-light-silver);
	color: var(--color-chinese-black);

	::placeholder {
		color: var(--color-spanish-grey);
	}
`
