import { graphql, useStaticQuery } from 'gatsby'
import { AboutMePageQuery } from 'graphqlTypes'
import React from 'react'

import Editorial from 'components/Editorial'
import HalfImageBlock from 'components/Editorial/HalfImageBlock'
import PageWrapper from 'components/PageWrapper'

const AboutMe = () => {
	const { strapi } = useStaticQuery(query) as NoUndefinedField<AboutMePageQuery>
	const { header, content } = strapi.aboutMePage

	return (
		<PageWrapper>
			<HalfImageBlock
				title={header.title}
				image={header.background}
				additionalWords={header.relatedWords.map(({ text }) => text)}
			/>
			<Editorial content={content} />
		</PageWrapper>
	)
}

const query = graphql`
	query AboutMePage {
		strapi {
			aboutMePage {
				content {
					__typename
					... on strapi_ComponentContentBlocksArticleCarousel {
						id
						title
					}
					... on strapi_ComponentContentBlocksBookCarousel {
						id
						title
					}
					... on strapi_ComponentContentBlocksContactForm {
						id
						description
						title
					}
					... on strapi_ComponentContentBlocksCircularBlock {
						id
						description
						image {
							...Image
						}
						imagePosition
						title
						button
					}
					... on strapi_ComponentContentBlocksQuote {
						id
						text
					}
					... on strapi_ComponentContentBlocksEvents {
						id
						title
					}
				}
				header {
					title
					relatedWords {
						text
					}
					background {
						...Image
					}
				}
			}
		}
	}
`

export default AboutMe
