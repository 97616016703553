import { styled } from '@linaria/react'

import BaseSection from 'components/Section'
import SectionHeader from 'components/SectionHeader'

export const Section = styled(BaseSection)`
	${SectionHeader} {
		margin-bottom: 32px;
	}
`

export const Book = styled.li``

export const BooksList = styled.ul`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
	grid-gap: 40px;

	justify-items: center;

	${Book} {
		width: 100%;
		max-width: 300px;
	}

	@media only screen and (min-width: 1000px) {
		${Book}:nth-child(odd) {
			margin-top: 100px;
		}
	}
`
