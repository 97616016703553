import { css } from '@linaria/core'

import './colors'
import './reset'

const styles = css`
	:global() {
		/* stylelint-disable no-invalid-position-at-import-rule */
		@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,600;0,700;1,600&family=Poppins&display=swap');

		html {
			font-size: 62.5%;
			background-color: var(--color-anti-flash-white);
		}

		body {
			font-size: 1.8rem;
			font-family: 'Mulish', sans-serif;
		}

		.sr-only {
			clip: rect(0 0 0 0);
			clip-path: inset(100%);
			height: 1px;
			overflow: hidden;
			position: absolute;
			white-space: nowrap;
			width: 1px;
		}

		.block-scroll {
			overflow: hidden;
		}
	}
`

export default styles
