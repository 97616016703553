import { css } from '@linaria/core'
import { styled } from '@linaria/react'

import Image from 'components/Image'
import SignatureLogo from 'components/Signature'

export const bounceAnimation = css`
	animation: bounce 2s infinite;

	@keyframes bounce {
		0%,
		20%,
		50%,
		80%,
		100% {
			transform: translateY(0);
		}
		40% {
			transform: translateY(-20px);
		}
		60% {
			transform: translateY(-10px);
		}
	}
`

export const Wrapper = styled.header`
	width: 100%;
	height: 100vh;
	position: relative;

	background-color: var(--color-gainsboro);
	background-clip: content-box;
`

export const TextWrapper = styled.div`
	bottom: 125px;
	position: absolute;
	z-index: 1;

	left: 0;
	right: 0;

	text-align: center;

	max-width: 570px;
	min-width: 300px;
	width: 40%;

	display: block;
	margin: auto;
`

export const Signature = styled(SignatureLogo)`
	width: 100%;
	color: var(--color-raisin-black);
`

export const BackgroundImage = styled(Image)`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: relative;

	::after {
		content: '';
		position: absolute;

		width: 100%;
		height: 100%;

		top: 0;
		left: 0;

		opacity: 0.2;
		background: var(--color-black);
	}
`

export const ImageOverlay = styled.div`
	background: white;
	opacity: 0.2;
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
`

export const ArrowDown = styled.img`
	position: absolute;
	left: 0;
	right: 0;

	margin: auto;
	bottom: 16px;
`
