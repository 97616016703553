import { graphql } from 'gatsby'
import { ArticlesIndexQuery } from 'graphqlTypes'
import React from 'react'

import ArticleBlock from 'components/Editorial/ArticleBlock'
import Markdown from 'components/Markdown'
import PageWrapper from 'components/PageWrapper'
import SEO from 'components/SEO'
import Text from 'components/Text'

import { ArticleWrapper, Background, Wrapper } from './ArticlePage.styles'

interface ArticlesIndexProps {
	data: NoUndefinedField<ArticlesIndexQuery>
}

const ArticlesIndex: React.FC<ArticlesIndexProps> = (props) => {
	const { strapi } = props.data
	const article = strapi.articles[0]

	return (
		<PageWrapper>
			<SEO
				title={article?.title}
				description={article?.description}
				imageUrl={article?.background?.url}
			/>
			<Background image={article?.background} />
			<Wrapper>
				<ArticleWrapper>
					<Text style="heading" element="h1">
						{article?.title}
					</Text>
					<br />
					<Markdown>{article?.text}</Markdown>
				</ArticleWrapper>
			</Wrapper>
			<ArticleBlock title="Artigos Relacionados" excludeSlug={article?.slug} />
		</PageWrapper>
	)
}

export const query = graphql`
	query ArticleIndex($slug: String) {
		strapi {
			articles(where: { slug_eq: $slug }) {
				title
				text
				description
				slug
				background {
					...Image
				}
			}
		}
	}
`

export default ArticlesIndex
