import { styled } from '@linaria/react'

const Card = styled.div`
	background: var(--color-white);

	border-radius: 8px;
	overflow: hidden;

	box-shadow: 0 2px 4px -1px rgba(29, 29, 32, 0.16),
		0 4px 5px 0 rgba(29, 29, 32, 0.05), 0 1px 10px 0 rgba(29, 29, 32, 0.08);
`

export default Card
