import React from 'react'

import { TextStyles } from 'components/Text'

import { Input as BaseInput, Label } from './Input.styles'

interface InputProps extends React.ComponentProps<'input'> {
	label: string
}

const Input: React.FC<InputProps> = ({ label, ...inputProps }) => (
	<Label className={TextStyles.inputLabel}>
		{label}
		<BaseInput className={TextStyles.inputText} {...inputProps} />
	</Label>
)

export default Input
