import { styled } from '@linaria/react'

import EventCard from 'components/EventCard'
import BaseSection from 'components/Section'
import SectionHeader from 'components/SectionHeader'

export const Section = styled(BaseSection)`
	display: flex;
	flex-direction: column;

	${SectionHeader} {
		margin-bottom: 90px;
	}
`

export const EventList = styled.ul`
	margin: auto;

	display: flex;
	flex-direction: column;
`

export const Event = styled.li`
	display: flex;
	border-left: 1px solid var(--color-dark-grey);
	position: relative;

	::before {
		display: block;

		content: '';
		width: 11px;
		height: 11px;

		background: var(--color-dark-grey);
		border-radius: 100%;

		position: absolute;
		left: -6px;
		top: 0;
	}

	${EventCard} {
		margin-top: -0.5em;
		padding-left: 20px;
		padding-bottom: 50px;
	}
`
