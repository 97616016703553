import React from 'react'

interface SignatureProps {
	className?: string
}

const Signature: React.FC<SignatureProps> = ({ className }) => (
	<svg
		className={className}
		width="420px"
		height="151px"
		viewBox="0 0 420 151"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		style={{ transform: 'rotate(4deg)' }}
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-108.000000, -235.000000)"
				stroke="currentColor"
				strokeWidth="3.2"
			>
				<g id="Forma-7" transform="translate(110.000000, 236.000000)">
					<path d="M153.221581,113.48 C148.991581,105.52 144.141581,98.7 141.691581,89.72 C141.361581,88.51 139.651581,82.69 137.921581,82.97 C134.281581,83.55 128.861581,100.18 127.881581,103.54 C127.681581,104.22 125.341581,110.26 124.381581,109.8 C122.921581,109.11 121.161581,94.23 120.171581,91.54 C119.801581,90.53 118.351581,85.84 116.661581,86.6 C112.341581,88.53 111.801581,102.29 110.701581,106.17 C110.251581,107.77 108.451581,112.03 106.521581,112.28 C104.991581,112.48 104.031581,109.26 103.661581,108.16 C102.081581,103.38 101.861581,98.46 100.361581,93.75 C100.151581,93.08 99.0915807,88.1 98.1315807,88.48 C96.5915807,89.08 96.3215807,94.14 96.0015807,95.39 C93.9715807,103.21 93.8115807,116.99 81.8015807,113.79 C72.5815807,111.33 69.5215807,86.23 75.5015807,79.75 C79.7515807,75.14 89.3415807,77.98 89.7615807,84.53"></path>
					<path d="M175.951581,106.87 C173.441581,102.5 173.371581,97.03 170.891581,92.43 C170.611581,91.9 168.751581,86.96 167.981581,87.13 C167.131581,87.32 166.681581,91.3 166.571581,91.9 C165.831581,95.66 165.581581,112.46 158.501581,108.82 C152.481581,105.72 151.711581,95.56 153.361581,89.81 C153.861581,88.08 154.501581,84.67 156.381581,83.69 C159.951581,81.83 160.481581,88.31 160.061581,89.97"></path>
					<path d="M257.831581,43.25 C258.251581,43.37 258.431581,47.87 258.481581,48.34 C259.031581,53.2 258.791581,58.29 258.261581,63.14 C256.611581,78.29 248.721581,118.41 224.621581,104.29 C216.641581,99.61 215.381581,81.56 216.061581,73.45 C217.501581,56.38 220.781581,39.59 224.001581,22.84 C225.411581,15.55 230.251581,7.07 230.891581,0"></path>
					<path d="M293.581581,87.55 C289.421581,82.02 286.401581,75.07 285.311581,67.8 C284.861581,64.83 284.621581,61.8 284.311581,58.82 C284.261581,58.39 284.191581,55.89 283.641581,55.72 C281.961581,55.2 280.651581,63.36 280.501581,63.96 C278.991581,70.32 278.241581,77.01 275.461581,82.98 C274.301581,85.47 272.581581,88.4 269.341581,87.92 C266.471581,87.5 265.401581,83.8 265.331581,81.24 C265.181581,75.19 265.241581,66.49 268.421581,61.12 C272.211581,54.73 275.711581,59.79 276.511581,64.29 C276.881581,66.38 277.591581,68.4 277.851581,70.52"></path>
					<path d="M311.611581,39.82 C308.891581,36.36 303.131581,40.01 301.761581,43.08 C300.841581,45.15 301.531581,48.29 302.211581,50.36 C306.031581,62 318.601581,71.29 319.771581,83.69 C320.881581,95.43 306.931581,93.94 305.391581,83.9"></path>
					<path d="M338.101581,39.64 C337.371581,40 337.601581,43.77 337.551581,44.37 C337.141581,49.25 337.141581,54.37 337.141581,59.26 C337.131581,80.6 339.031581,101.89 339.741581,123.21 C339.951581,129.39 340.091581,135.56 340.091581,141.74 C340.091581,143.31 340.511581,146.78 338.481581,147.34 C336.121581,147.99 335.441581,144.01 334.971581,142.34 C333.201581,136.09 332.731581,129.67 332.611581,123.2 C332.231581,103.53 337.381581,78.6 340.951581,58.97 C341.791581,54.35 342.161581,41.45 347.991581,39.16 C350.271581,38.26 351.811581,47.68 351.911581,49.08 C352.541581,57.97 352.711581,66.93 353.051581,75.85 C353.161581,78.57 352.321581,84.47 354.341581,86.55"></path>
					<path d="M382.891581,78.65 C377.771581,76.68 378.561581,64 377.851581,60.12 C377.601581,58.78 377.281581,57.45 377.041581,56.1 C376.871581,55.11 377.521581,52.66 376.641581,53.13 C376.001581,53.47 375.881581,58.6 375.811581,59.29 C375.671581,60.58 375.531581,61.86 375.281581,63.13 C375.081581,64.15 374.861581,65.1 374.561581,66.09 C373.931581,68.16 371.691581,81.02 366.801581,76.3 C365.521581,75.06 365.931581,71.86 365.751581,70.19 C365.121581,64.55 364.751581,52.82 367.531581,47.75 C369.931581,43.36 375.451581,43.15 375.761581,49.17"></path>
					<path d="M416.321581,74.98 C413.051581,69.94 413.331581,63.35 408.511581,58.86 C405.841581,56.37 398.231581,56.96 396.651581,54.32 C396.381581,53.87 398.071581,51.46 398.241581,51.1 C398.941581,49.69 406.121581,39.21 405.291581,37.89 C404.991581,37.41 404.611581,38.8 404.291581,39.27 C403.631581,40.24 403.161581,41.33 402.591581,42.35 C401.071581,45.05 399.801581,47.96 398.491581,50.77 C394.641581,59.04 391.791581,67.08 389.741581,75.95 C389.571581,76.67 388.581581,83.6 389.251581,83.74 C390.071581,83.92 389.361581,82.06 389.441581,81.23 C389.641581,79.19 389.801581,77.14 389.981581,75.09 C390.551581,68.48 391.051581,61.67 391.061581,55.04 C391.061581,52.23 390.281581,47.46 391.881581,44.95"></path>
					<path d="M10.7115807,23.18 C11.6515807,23.32 11.9115807,28.34 12.0015807,29.31 C12.5415807,35.63 14.6315807,41.78 16.2515807,47.86 C23.6915807,75.82 24.5915807,106.18 24.6715807,135"></path>
					<path d="M1.13158065,28.09 C-2.38841935,22.95 2.88158065,11.68 9.00158065,10.82 C14.6115807,10.03 21.5515807,15.68 24.9615807,19.94 C32.2415807,29.01 36.1515807,43.52 37.9115807,54.84 C41.4615807,77.67 45.5815807,102.28 33.5915807,123.45 C30.3315807,129.21 24.8315807,142.89 17.5915807,144.82 C16.1215807,145.21 11.8415807,146.45 10.4415807,145.44 C7.88158065,143.59 7.48158065,138.44 7.05158065,135.7 C5.69158065,127.04 5.31158065,113.45 9.17158065,105.23 C17.4315807,87.65 37.1015807,112.47 48.7315807,107.01 C55.1815807,103.98 60.2515807,94.09 61.7415807,87.55 C62.0715807,86.11 63.3115807,81.1 62.0815807,79.86 C61.2315807,79 61.5915807,82.25 61.5515807,83.46 C61.4915807,85.85 61.2115807,88.26 61.0215807,90.64 C60.4815807,97.71 59.7615807,104.68 59.7615807,111.79"></path>
					<path d="M60.5515807,66.03 C61.3015807,68.27 62.8015807,70.14 63.8315807,72.22"></path>
				</g>
			</g>
		</g>
	</svg>
)

export default Signature
