import { css } from '@linaria/core'
import { styled } from '@linaria/react'

import waveSrc from './wave-base.svg'

export const animatedWave = css`
	@keyframes move_wave {
		0% {
			background-position: 0% center;
		}
		50% {
			background-position: 100% center;
		}
		100% {
			background-position: 0% center;
		}
	}

	animation-duration: 40s;
	animation-name: move_wave;
	animation-iteration-count: infinite;
`

export const PageWrapper = styled.div`
	position: absolute;
	top: 25%;
	left: 0;

	width: 100%;
	height: 70%;
`

export const Wrapper = styled.div`
	position: sticky;
	top: 0;

	height: 100vh;
	overflow: hidden;

	z-index: -1;

	line-height: 0;

	display: flex;
	align-items: center;
`

export const WaveBase = styled.div`
	width: 100%;
	height: 80%;

	background-image: url(${waveSrc});
	background-size: auto 80vh;
	background-repeat: no-repeat;
	background-position: center;
`
