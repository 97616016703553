import { styled } from '@linaria/react'
import { ImageFragment } from 'graphqlTypes'
import React from 'react'

import Card from 'components/Card'
import Markdown from 'components/Markdown'

import {
	BookImage,
	Button,
	TextWrapper,
	Title,
	Wrapper,
} from './BookSingleBlock.styles'

interface BookSingleBlockProps {
	className?: string
	title: string
	description: string
	image: ImageFragment
	purchaseLink: string
	id: string
}

const CardedImage = (
	props: React.ComponentPropsWithoutRef<typeof BookImage>
) => <Card as={BookImage} {...props} />

const LinkedButton = (
	props: Pick<
		React.ComponentPropsWithoutRef<'a'>,
		'href' | 'target' | 'rel' | 'children'
	>
) => <Button as="a" size="big" {...props} />

const BookSingleBlock: React.FC<BookSingleBlockProps> = (props) => {
	const { className, id, title, description, image, purchaseLink } = props

	return (
		<Wrapper
			className={className}
			id={id}
			itemScope
			itemType="https://schema.org/Book"
		>
			<CardedImage image={image} itemType="image" />
			<Title element="h1" style="heading" itemType="title">
				{title}
			</Title>
			<TextWrapper itemType="description">
				<Markdown>{description}</Markdown>
			</TextWrapper>
			<LinkedButton href={purchaseLink} target="_blank" rel="noopener">
				Comprar
			</LinkedButton>
		</Wrapper>
	)
}

export default styled(BookSingleBlock)``
