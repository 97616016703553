import { styled } from '@linaria/react'

import BookSingleBlock from 'components/Editorial/BookSingleBlock'
import BaseSection from 'components/Section'

export const Section = styled(BaseSection)``

export const BookItem = styled(BookSingleBlock)`
	@media only screen and (min-width: 768px) {
		:nth-child(even) {
			grid-template-areas:
				'title image'
				'description image'
				'button image';
		}
	}

	& + & {
		margin-top: 128px;
	}
`
