import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import React from 'react'

import PageWrapper from 'components/PageWrapper'
import SEO from 'components/SEO'
import Text, { TextStyles } from 'components/Text'

import notFoundImage from 'icons/404.svg'
import arrowForward from 'icons/arrow-forward.svg'

const NotFoundImage = styled.img`
	max-width: max(320px, 33%);
`

const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	padding: 0 20px;

	flex: 1;
	text-align: center;

	.${TextStyles.halfImageTitle} {
		margin-bottom: 16px;
	}

	.${TextStyles.paragraph} {
		font-size: 2.2rem;
		margin-bottom: 24px;
	}
`

const link = css`
	display: flex;
	align-items: center;
	color: var(--color-deep-aquamarine);
	font-size: 2rem;

	::after {
		margin-left: 7px;
		filter: invert(42%) sepia(8%) saturate(1955%) hue-rotate(100deg)
			brightness(113%) contrast(100%);

		content: '';
		width: 24px;
		height: 24px;

		flex-shrink: 0;

		background: url(${arrowForward});
	}
`

interface NotFoundPageProps {
	error?: boolean
}

const NOT_FOUND_COPY = {
	'404': {
		title: 'Página não encontrada',
		description:
			'O link que seguiu pode estar quebrado ou a página não existe.',
	},
	'500': {
		title: 'Alguma coisa partiu',
		description:
			'O link que seguiu pode estar danificado, estamos a corrigir o mais rapido possivel',
	},
} as const

const NotFoundPage = (props: NotFoundPageProps) => {
	const copy = NOT_FOUND_COPY[props?.error ? '500' : '404']

	return (
		<>
			<SEO title={copy.title} />
			<PageWrapper>
				<ContentWrapper>
					<NotFoundImage src={notFoundImage} />
					<Text element="h1" style="halfImageTitle">
						{copy.title}
					</Text>
					<Text>{copy.description}</Text>
					<Text className={link} element={Link} to="/">
						Voltar à homepage
					</Text>
				</ContentWrapper>
			</PageWrapper>
		</>
	)
}

export default NotFoundPage
