import { styled } from '@linaria/react'

import Card from 'components/Card'
import Image from 'components/Image'

export const Background = styled(Image)`
	width: 100%;
	height: 350px;

	@media only screen and (min-width: 768px) {
		height: 500px;
	}
`

export const Wrapper = styled.div`
	max-width: 1048px;
	width: 100%;
	margin: -128px auto 120px;
`

export const ArticleWrapper = styled(Card)`
	padding: 20px;
	margin: 0 24px;

	@media only screen and (min-width: 768px) {
		padding: 50px;
	}
`
