import { styled } from '@linaria/react'

import Image from 'components/Image'
import BaseSection from 'components/Section'
import { TextStyles } from 'components/Text'

export const Section = styled(BaseSection)`
	display: flex;
	overflow: hidden;
	position: relative;

	@media only screen and (max-width: 767px) {
		order: -1;
	}
`

export const TextWrapper = styled.div`
	right: 15%;
	position: relative;

	width: min(40%, 700px);
	aspect-ratio: 1;
	margin-bottom: 60px;
	flex-shrink: 0;
	align-self: flex-start;
	line-height: 0;

	.${TextStyles.halfImageTitle} {
		position: absolute;
		top: 60%;
		left: 0;
		transform: translate(-50%, 0%);
		padding: 20px;
	}

	.${TextStyles.halfImageWord} {
		position: absolute;
		padding: 20px;

		:nth-of-type(1):not(:nth-last-of-type(1)) {
			bottom: 0;
			right: 0;
		}

		:nth-of-type(2) {
			top: 25%;
			right: 0;
			transform: translate(50%, 0%);
		}

		:nth-of-type(1):nth-last-of-type(1) {
			top: 25%;
			left: 0;
			transform: translate(-50%, 0%);

			& ~ .${TextStyles.halfImageTitle} {
				top: 60%;
				left: auto;
				right: -20%;
				transform: none;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		width: 55%;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		align-self: center;

		.${TextStyles.halfImageTitle} {
			font-size: 2.4rem;
			padding: 8px;
		}

		.${TextStyles.halfImageWord} {
			font-size: 1.6rem;
			padding: 8px;
		}
	}
`

export const HalfImage = styled(Image)`
	width: 60%;
	position: relative;
	align-self: flex-end;
	margin-top: 60px;

	::after {
		content: '';
		position: absolute;
		display: block;

		width: 100%;
		height: 100%;

		top: 0;
		left: 0;

		opacity: 0.2;
		background: white;
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
		aspect-ratio: 1;
		margin-top: 0px;
	}
`
