import { styled } from '@linaria/react'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'
import React from 'react'

import Text from 'components/Text'

interface EventCardProps {
	className?: string
	location: string
	title: string
	time: string
}

const EventCard: React.FC<EventCardProps> = ({
	className,
	location,
	title,
	time,
}) => (
	<article className={className}>
		<Text style="eventLocation">{location}</Text>
		<Text style="eventTitle" element="h1">
			{title}
		</Text>
		<Text style="eventDate" element="time" dateTime={time}>
			{format(new Date(time), 'd MMM yyyy | HH:mm', { locale: pt })}
		</Text>
	</article>
)

export default styled(EventCard)``
