import { css } from '@linaria/core'
import { styled } from '@linaria/react'

import Image from 'components/Image'
import BaseSection from 'components/Section'
import SectionHeader from 'components/SectionHeader'

export const CircularImage = styled(Image)`
	width: 320px;
	border-radius: 100%;
	aspect-ratio: 1;
	flex-shrink: 0;
	overflow: hidden;
`

export const ImageWrapper = styled.div``

export const TextWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 32px;

	${SectionHeader} {
		align-self: stretch;
	}
`

export const mirroredImageClassName = css`
	@media only screen and (min-width: 768px) {
		${TextWrapper} {
			order: 1;
		}

		${ImageWrapper} {
			order: 2;
		}
	}
`

export const Section = styled(BaseSection)`
	display: flex;
	align-items: flex-start;
	gap: 64px;

	max-width: 1000px;

	@media only screen and (max-width: 767px) {
		flex-direction: column;
		align-items: center;

		${TextWrapper} {
			order: 1;
		}

		${ImageWrapper} {
			order: 2;
		}
	}
`
