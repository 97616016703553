import React from 'react'

import { PageWrapper, WaveBase, Wrapper, animatedWave } from './Wave.styles'

const Wave = () => (
	<PageWrapper>
		<Wrapper>
			<WaveBase className={animatedWave} />
		</Wrapper>
	</PageWrapper>
)

export default Wave
