import { styled } from '@linaria/react'

import BaseCard from 'components/Card'
import BaseImage from 'components/Image'
import Text from 'components/Text'

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`

export const Title = styled(Text)`
	margin-bottom: 16px;
`

export const Image = styled(BaseImage)`
	width: 100%;
	height: 192px;

	object-fit: cover;
`

export const Card = styled(BaseCard)`
	height: 100%;
`
