import { ImageFragment } from 'graphqlTypes'
import React from 'react'

import Text from 'components/Text'

import arrowDown from 'icons/arrow-down.svg'

import {
	ArrowDown,
	BackgroundImage,
	ImageOverlay,
	Signature,
	TextWrapper,
	Wrapper,
	bounceAnimation,
} from './HomePageHeader.styles'

interface HomePageHeaderProps {
	image: ImageFragment
	description: string
}

const HomePageHeader: React.FC<HomePageHeaderProps> = ({
	image,
	description,
}) => (
	<Wrapper>
		<BackgroundImage image={image} />
		<ImageOverlay />

		<TextWrapper>
			<Signature />
			<Text style="homePageDescription" element="p">
				{description}
			</Text>
		</TextWrapper>
		<a
			href="#main"
			onClick={(event) => {
				event.preventDefault()
				document.getElementById('main')?.scrollIntoView({ behavior: 'smooth' })
			}}
		>
			<ArrowDown className={bounceAnimation} src={arrowDown} />
			<span className="sr-only">Ir para a seçcão principal</span>
		</a>
	</Wrapper>
)

export default HomePageHeader
