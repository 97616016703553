import { cx } from '@linaria/core'
import { Link } from 'gatsby'
import {
	ImageFragment,
	Strapi_ComponentContentBlocksCircularBlock,
} from 'graphqlTypes'
import React from 'react'

import Button from 'components/Button'
import Markdown from 'components/Markdown'
import Text from 'components/Text'

import {
	CircularImage,
	ImageWrapper,
	Section,
	TextWrapper,
	mirroredImageClassName,
} from './CircularTextActionBlock.styles'

interface CircularTextActionBlockProps {
	title: string
	content: string
	image: ImageFragment
	button?: Strapi_ComponentContentBlocksCircularBlock['button']
	mirroredImage?: boolean
}

const CircularTextActionBlock: React.FC<CircularTextActionBlockProps> = (
	props
) => {
	const { title, content, image, button, mirroredImage } = props

	let actionButton = null
	switch (button) {
		case 'sobremim': {
			actionButton = (
				<Button size="big" as={Link} {...{ to: '/about-me' }}>
					Sobre Mim
				</Button>
			)
			break
		}
		case 'contatos': {
			actionButton = (
				<Button size="big" as={Link} {...{ to: '/contact-us' }}>
					Contatos
				</Button>
			)
			break
		}
		case 'artigos': {
			actionButton = (
				<Button size="big" as={Link} {...{ to: '/articles' }}>
					Artigos
				</Button>
			)
			break
		}
		case 'livros': {
			actionButton = (
				<Button size="big" as={Link} {...{ to: '/books' }}>
					Livros
				</Button>
			)
			break
		}
	}

	return (
		<Section className={cx(mirroredImage && mirroredImageClassName)}>
			<ImageWrapper>
				<CircularImage image={image} />
			</ImageWrapper>
			<TextWrapper>
				<Text element="h1" style="circularImageTitle">
					{title}
				</Text>
				<Text>
					<Markdown>{content}</Markdown>
				</Text>
				{actionButton}
			</TextWrapper>
		</Section>
	)
}

export default CircularTextActionBlock
