import { graphql, useStaticQuery } from 'gatsby'
import { HomePageQuery } from 'graphqlTypes'
import React from 'react'
import 'styles'

import Editorial from 'components/Editorial'
import HomePageHeader from 'components/Editorial/HomePageHeader'
import PageWrapper from 'components/PageWrapper'
import SEO from 'components/SEO'

type NoUndefinedField<T> = {
	[P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>
}

const IndexPage = () => {
	const { strapi } = useStaticQuery(query) as NoUndefinedField<HomePageQuery>
	const { description, image } = strapi.homePage.header

	return (
		<>
			<SEO
				title="Página Principal"
				description="Esta é minha pagina principal! "
			/>
			<HomePageHeader description={description} image={image} />
			<PageWrapper>
				<Editorial content={strapi.homePage.content} />
			</PageWrapper>
		</>
	)
}

const query = graphql`
	fragment Image on strapi_UploadFile {
		alternativeText
		caption
		url
	}

	query HomePage {
		strapi {
			homePage {
				header {
					description
					image {
						...Image
					}
				}
				content {
					__typename
					... on strapi_ComponentContentBlocksArticleCarousel {
						id
						title
					}
					... on strapi_ComponentContentBlocksBookCarousel {
						id
						title
					}
					... on strapi_ComponentContentBlocksContactForm {
						id
						description
						title
					}
					... on strapi_ComponentContentBlocksCircularBlock {
						id
						description
						image {
							...Image
						}
						imagePosition
						title
						button
					}
					... on strapi_ComponentContentBlocksQuote {
						id
						text
					}
					... on strapi_ComponentContentBlocksEvents {
						id
						title
					}
				}
			}
		}
	}
`

export default IndexPage
