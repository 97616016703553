import { format } from 'date-fns'
import { pt } from 'date-fns/locale'
import { ImageFragment } from 'graphqlTypes'
import React from 'react'

import Text from 'components/Text'

import { Card, Image, TextWrapper, Title } from './ArticleCard.styles'

export interface ArticleCardProps {
	title: string
	description: string
	image: ImageFragment
	authorName: string
	date: string
}

const ArticleCard: React.FC<ArticleCardProps> = ({
	title,
	description,
	image,
	authorName,
	date,
}) => (
	<Card as="article" itemScope itemType="https://schema.org/Article">
		<Image image={image} itemType="image" />
		<TextWrapper>
			<Title element="h1" style="cardHeader" itemProp="name">
				{title}
			</Title>
			<Text element="p" style="cardDescription" itemProp="description">
				{description}
			</Text>
			<br />
			<Text style="cardDateAuthor">
				<span itemProp="author">{authorName}</span> .{' '}
				<time dateTime={date}>
					{format(new Date(date), 'd MMMM yyyy', { locale: pt })}
				</time>
			</Text>
		</TextWrapper>
	</Card>
)

export default ArticleCard
