import React from 'react'

import Text from 'components/Text'

import quoteIcon from 'icons/quotation-marks.svg'

import { Icon, Section, Wrapper } from './QuoteBlock.styles'

interface QuoteBlockProps {
	children: string
}

const QuoteBlock = ({ children }: QuoteBlockProps) => (
	<Section>
		<Wrapper>
			<Icon src={quoteIcon} />
			<Text element="p" style={['quote']}>
				{children.split(/\n/).map((text, index) => (
					<React.Fragment key={index}>
						{text}
						<br />
					</React.Fragment>
				))}
			</Text>
		</Wrapper>
	</Section>
)

export default QuoteBlock
