import { css, cx } from '@linaria/core'

export const heading = css`
	font-family: 'Poppins', sans-serif;
	font-size: 3.1rem;
	font-weight: 400;

	line-height: 1.5;
	hyphens: auto;

	color: var(--color-deep-aquamarine);
`

export const heading2 = css`
	font-family: 'Poppins', sans-serif;
	font-size: 2.4rem;
	font-weight: 400;

	line-height: 1.5;

	color: var(--color-deep-aquamarine);
`

export const paragraph = css`
	font-family: 'Mulish', sans-serif;
	font-size: 1.8rem;
	font-weight: 400;

	line-height: 1.5;

	color: var(--color-dark-charcoal);
`

export const header = css`
	font-family: 'Mulish', sans-serif;
	font-size: 1.8rem;
	font-weight: 600;

	line-height: 1.5;
	text-transform: uppercase;

	color: var(--color-dark-charcoal);
`

export const headerActive = cx(
	header,
	css`
		color: var(--color-deep-aquamarine);
		border-bottom: 1px solid currentColor;
	`
)

export const homePageDescription = cx(
	heading,
	css`
		color: var(--color-raisin-black);
		font-size: 2.4rem;
		hyphens: none;
	`
)

export const cardHeader = css`
	font-family: 'Mulish', sans-serif;
	font-size: 2rem;
	font-weight: 600;

	line-height: 1.5;

	color: var(--color-dark-charcoal);
`

export const cardDescription = css`
	font-family: 'Mulish', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;

	line-height: 1.5;

	color: var(--color-dark-charcoal);
`

export const cardDateAuthor = css`
	font-family: 'Mulish', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;

	line-height: 1.5;
	text-transform: capitalize;

	color: var(--color-spanish-grey);
`

export const dashLink = css`
	display: flex;
	align-items: center;

	font-family: 'Poppins', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;

	line-height: 2.25;

	color: var(--color-deep-aquamarine);

	::before {
		content: '';
		width: 50px;
		border-top: 1px solid var(--color-quick-silver);
		margin-right: 16px;
	}
`

export const eventLocation = css`
	font-family: 'Poppins', sans-serif;
	font-size: 1.4rem;
	font-weight: 400;

	line-height: 1.5;
	color: var(--color-deep-aquamarine);
`

export const eventTitle = css`
	font-family: 'Poppins', sans-serif;
	font-size: 1.8rem;
	font-weight: 400;

	line-height: 1.5;
	color: var(--color-raisin-black);
`

export const eventDate = css`
	font-family: 'Poppins', sans-serif;
	font-size: 1.4rem;
	font-weight: 400;

	line-height: 1.5;
	text-transform: capitalize;
	color: var(--color-dark-grey);
`

export const inputLabel = css`
	font-family: 'Poppins', sans-serif;
	font-size: 1.2rem;
	font-weight: 400;

	line-height: 1.5;
	color: var(--color-dark-charcoal);
`

export const inputText = css`
	font-family: 'Poppins', sans-serif;
	font-size: 1.2rem;
	font-weight: 400;

	line-height: 1.5;
	color: var(--color-dark-charcoal-20);
`

export const actionText = css`
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	font-weight: 400;

	line-height: 1.4;
	color: var(--color-deep-aquamarine);
`

export const halfImageTitle = css`
	font-family: 'Mulish', sans-serif;
	font-size: 4.8rem;
	font-weight: 600;

	line-height: 1.25;

	color: var(--color-chinese-black);
`

export const halfImageWord = css`
	font-family: 'Mulish', sans-serif;
	font-size: 2.4rem;
	font-weight: 600;

	line-height: 1.25;

	color: var(--color-chinese-black);
`

export const circularImageTitle = cx(
	heading2,
	css`
		width: 100%;
		display: flex;

		::after {
			margin-left: 32px;
			content: '';
			flex: 1;
			border-bottom: 1px solid var(--color-quick-silver);
		}
	`
)

export const quote = css`
	font-family: 'Mulish', sans-serif;
	font-size: 2.4rem;
	font-style: italic;
	font-weight: 600;
	line-height: 1.25;

	color: var(--color-dark-charcoal);
`

export const pre = css`
	white-space: pre;
`

export const success = css`
	color: #2d6a4f;
`

export const error = css`
	color: #b00020;
`

export const white = css`
	color: var(--color-white);
`
