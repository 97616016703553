import { graphql, useStaticQuery } from 'gatsby'
import { BooksQuery } from 'graphqlTypes'
import React from 'react'

import HalfImageBlock from 'components/Editorial/HalfImageBlock'
import PageWrapper from 'components/PageWrapper'
import Section from 'components/Section'

import { BookItem } from './Books.styles'

const Books = () => {
	const { strapi } = useStaticQuery(query) as NoUndefinedField<BooksQuery>

	const { bookPage, books } = strapi
	const { title, relatedWords, background } = bookPage.header

	return (
		<PageWrapper>
			<HalfImageBlock
				title={title}
				image={background}
				additionalWords={relatedWords.map(({ text }) => text)}
			/>
			<Section>
				{books.map((book) => (
					<BookItem
						id={book.slug}
						title={book.title}
						image={book.bookCover}
						description={book.description}
						purchaseLink={book.storeLink}
					/>
				))}
			</Section>
		</PageWrapper>
	)
}

const query = graphql`
	query Books {
		strapi {
			bookPage {
				header {
					relatedWords {
						text
					}
					title
					background {
						...Image
					}
				}
			}

			books {
				description
				title
				slug
				storeLink
				bookCover {
					...Image
				}
			}
		}
	}
`

export default Books
