import { cx } from '@linaria/core'
import React from 'react'

import { fullWidthSection, paddedWidthSection } from './Section.styles'

interface SectionProps {
	className?: string
	width?: 'full' | 'padded'
}

const Section: React.FC<SectionProps> = (props) => {
	const { className, children, width = 'padded' } = props
	return (
		<section
			className={cx(
				className,
				width === 'full' ? fullWidthSection : paddedWidthSection
			)}
		>
			{children}
		</section>
	)
}

export default Section
