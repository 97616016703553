import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { breakpoints } from 'styles'

import SignatureLogo from 'components/Signature'

// Icon
import close from 'icons/close.svg'
import menu from 'icons/menu.svg'

export const NavigationBar = styled.nav`
	position: sticky;

	top: -1px;
	/* Compensate for the -1px top */
	padding-top: 1px;

	width: 100%;
	backdrop-filter: blur(3px);

	z-index: 1;

	transition-property: background, box-shadow;
	transition-duration: 0.5s;

	/* Fix the logo line-height */
	line-height: 0;

	&[data-opened='true'] {
		position: fixed;
		backdrop-filter: none;
	}

	::after {
		content: '';

		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;
		background: var(--color-anti-flash-white);
		opacity: 0.5;
	}
`

export const stickyMenu = css`
	/* TODO: Show only when the menu is sticky */
	background: #ffffffd0;
	box-shadow: 0 0 1px 0 var(--color-quick-silver);
`

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0 max(24px, min(7%, 75px));
	margin: 0 auto;

	width: 100%;
	height: 100px;
	max-width: 1440px;
`

export const Signature = styled(SignatureLogo)`
	width: 120px;
	flex-shrink: 0;
	color: black;
	line-height: 0;
`

export const List = styled.ul`
	display: flex;
	gap: 30px;

	@media only screen and (max-width: 767px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: white;
		flex-direction: column;
		align-items: center;
		gap: 40px;

		&& {
			padding-top: 88px;
		}
	}
`

export const MenuToggle = styled.input`
	width: 42px;
	height: 42px;
	appearance: none;

	background-image: url(${menu});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 42px;

	&:checked {
		background-image: url(${close});
		position: relative;
		z-index: 1;
	}

	/* TODO: Extend the hamburguer menu to tablet */
	@media only screen and (max-width: 767px) {
		&:not(:checked) {
			& + ${List} {
				display: none;
			}
		}
	}

	@media ${breakpoints.minTablet} {
		display: none;
	}
`
