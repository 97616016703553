import { styled } from '@linaria/react'

import BaseSection from 'components/Section'
import SectionHeader from 'components/SectionHeader'
import Text from 'components/Text'

// Icon
import arrowForward from 'icons/arrow-forward.svg'

export const CardList = styled.ul`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
	grid-gap: 32px;
	justify-items: center;
`

export const Card = styled.li`
	justify-self: stretch;
	align-self: stretch;
`

export const SeeMoreLink = styled(Text)`
	display: flex;
	align-items: center;

	::after {
		margin-left: 7px;
		filter: invert(42%) sepia(8%) saturate(1955%) hue-rotate(100deg)
			brightness(113%) contrast(100%);

		content: '';
		width: 24px;
		height: 24px;

		flex-shrink: 0;

		background: url(${arrowForward});
	}
`

export const Section = styled(BaseSection)`
	${SectionHeader} {
		margin-bottom: 32px;

		@media only screen and (max-width: 767px) {
			${SeeMoreLink} {
				display: none;
			}
		}
	}
`

export const MobileActionWrapper = styled.div`
	margin-top: 32px;
	display: flex;
	justify-content: center;

	@media only screen and (min-width: 768px) {
		display: none;
	}
`
