import { ImageFragment } from 'graphqlTypes'
import React, { createRef, useEffect, useRef, useState } from 'react'

import Text from 'components/Text'

import { HalfImage, Section, TextWrapper } from './HalfImageBlock.styles'

interface HalfImageBlockProps {
	title: string
	additionalWords: string[]
	image: ImageFragment
}

interface Position {
	x: number
	y: number
	width: number
	height: number
}

const OBSERVER_THRESHOLD = new Array(100)
	.fill(null)
	.map((_, index) => index * 0.01)

const HalfImageBlock: React.FC<HalfImageBlockProps> = (props) => {
	const { title, image, additionalWords } = props

	const textWrapperRef = useRef<HTMLDivElement>(null)

	const elementsCount = [title, ...additionalWords]
	const [elementsRefs, setElementRefs] = useState<
		React.RefObject<HTMLElement>[]
	>([])

	const [positionElements, setPositionElements] = useState<
		Record<number, Position>
	>({})

	const [rectWidth, setRectWidth] = useState(0)
	useEffect(() => {
		if (!textWrapperRef.current) {
			return
		}

		const resizeObserver = new ResizeObserver(([rect]) => {
			setRectWidth(rect.contentRect.width)
		})

		resizeObserver.observe(textWrapperRef.current)

		return () => {
			resizeObserver.disconnect()
		}
	}, [])

	const ratioCalculator = (num: number) => Math.round((num * 100) / rectWidth)

	// Refresh or clear the ref array
	useEffect(() => {
		setElementRefs(() =>
			new Array(elementsCount.length)
				.fill(null)
				.map((_, i) => elementsRefs[i] || createRef())
		)
	}, [elementsRefs.length])

	// Assemble the Listener
	useEffect(() => {
		if (!textWrapperRef.current) {
			return
		}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const index = elementsRefs.findIndex(
						(element) => element.current === entry.target
					)

					setPositionElements((positionElements) => ({
						...positionElements,
						[index]: {
							x: Math.ceil(
								((entry.boundingClientRect.x - (entry.rootBounds?.x || 0)) *
									100) /
									(entry.rootBounds?.width || 0)
							),
							y: Math.ceil(
								((entry.boundingClientRect.y - (entry.rootBounds?.y || 0)) *
									100) /
									(entry.rootBounds?.width || 0)
							),
							width: entry.boundingClientRect.width,
							height: entry.boundingClientRect.height,
						},
					}))
				})
			},
			{
				root: textWrapperRef.current,
				threshold: OBSERVER_THRESHOLD,
			}
		)

		elementsRefs.forEach((elements) => {
			elements.current && observer.observe(elements.current)
		})

		return () => {
			observer.disconnect()
		}
	}, [textWrapperRef.current, elementsRefs])

	const [titleRef, ...additionalRefs] = elementsRefs

	return (
		<Section width="full">
			<HalfImage image={image} />
			<TextWrapper ref={textWrapperRef}>
				<svg viewBox="0 0 100 100">
					<mask id="mask">
						<rect x="0" y="0" width="100" height="100" fill="white"></rect>
						{elementsRefs.map((_, index) => {
							const positions = positionElements[index]

							if (!positions) {
								return null
							}

							return (
								<rect
									x={positions.x}
									y={positions.y}
									width={ratioCalculator(positions.width)}
									height={ratioCalculator(positions.height)}
									fill="black"
									key={index}
								></rect>
							)
						})}
					</mask>
					<rect
						x="0"
						y="0"
						width="100%"
						height="100%"
						vectorEffect="non-scaling-stroke"
						strokeWidth="2px"
						stroke="black"
						fill="none"
						mask="url('#mask')"
					></rect>
				</svg>

				{additionalWords.map((word, index) => (
					<Text style="halfImageWord" ref={additionalRefs[index]}>
						{word}
					</Text>
				))}

				<Text element="h1" style="halfImageTitle" ref={titleRef}>
					{title}
				</Text>
			</TextWrapper>
		</Section>
	)
}

export default HalfImageBlock
