import { styled } from '@linaria/react'
import { ImageFragment } from 'graphqlTypes'
import React from 'react'

import Image from 'components/Image'

import { Card } from './BookCard.styles'

interface BookCardProps {
	className?: string
	name: string
	image: ImageFragment
}

const CardedImage = (props: React.ComponentPropsWithoutRef<typeof Image>) => (
	<Card as={Image} {...props} />
)

const BookCard: React.FC<BookCardProps> = ({ className, name, image }) => (
	<article className={className} itemScope itemType="https://schema.org/Book">
		<CardedImage itemType="image" image={image} />
		<h1 className="sr-only" itemType="title">
			{name}
		</h1>
	</article>
)

export default styled(BookCard)``
