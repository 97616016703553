import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Paragraph } from './Markdown.styles'

interface MarkdownProps {
	children: string
}

const Markdown = ({ children }: MarkdownProps) => (
	<ReactMarkdown
		components={{ p: ({ children }) => <Paragraph>{children}</Paragraph> }}
	>
		{children}
	</ReactMarkdown>
)

export default Markdown
