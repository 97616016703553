import { graphql, useStaticQuery } from 'gatsby'
import { ContactInfoQuery } from 'graphqlTypes'
import React, { useState } from 'react'

import Input from 'components/Input'
import Markdown from 'components/Markdown'
import SectionHeader from 'components/SectionHeader'
import Text from 'components/Text'
import TextArea from 'components/TextArea'

import locationIcon from 'icons/location.svg'
import phoneIcon from 'icons/phone.svg'

import {
	ContactBlockWrapper,
	ContactWrapper,
	FormCard,
	Icon,
	InnerWrapper,
	Section,
	SubmitButton,
	Wrapper,
} from './ContactUsBlock.styles'

interface ContactUsBlockProps {
	title: string
	description: string
}

interface FormElements extends HTMLFormControlsCollection {
	name: HTMLInputElement
	email: HTMLInputElement
	message: HTMLInputElement
}

interface ContactFormElement extends HTMLDivElement {
	readonly elements: FormElements
}

interface Message {
	body: string
	state: 'success' | 'error'
}

const ContactUsBlock: React.FC<ContactUsBlockProps> = ({
	title,
	description,
}) => {
	const [message, setMessage] = useState<Message | null>(null)
	const [loading, setLoading] = useState(false)

	const { strapi } = useStaticQuery(query) as NoUndefinedField<ContactInfoQuery>
	const { phone, address } = strapi.contactInfo

	const handleOnSubmit = async (event: React.FormEvent<ContactFormElement>) => {
		event.preventDefault()
		setMessage(null)

		const body = {
			name: event.currentTarget.elements.name.value,
			email: event.currentTarget.elements.email.value,
			message: event.currentTarget.elements.message.value,
		}

		if (
			// Validate that all the fields are present
			Object.values(body).filter((string) => string.length > 1).length !== 3
		) {
			setMessage({ body: 'Todos os campos são obrigatórios', state: 'success' })
			return
		}

		setLoading(true)

		try {
			await fetch(`${process.env.GATSBY_API_URL}/emails`, {
				method: 'POST',
				body: JSON.stringify(body),
			})
			setMessage({
				body: 'Formulário submetido com successo',
				state: 'success',
			})
		} catch (e) {
			setMessage({
				body: 'Existe um problema ao submeter o formulário',
				state: 'error',
			})
		}

		setLoading(false)
	}

	return (
		<Section>
			<SectionHeader title={title} />
			<Wrapper>
				<InnerWrapper>
					<Text>
						<Markdown>{description}</Markdown>
						<ContactBlockWrapper>
							<ContactWrapper>
								<Icon src={phoneIcon} />
								<Text element="a" href={`tel:${phone}`}>
									{phone}
								</Text>
							</ContactWrapper>
							<ContactWrapper>
								<Icon src={locationIcon} />
								<Text style={['paragraph']}>{address}</Text>
							</ContactWrapper>
						</ContactBlockWrapper>
					</Text>
				</InnerWrapper>
				<InnerWrapper>
					<FormCard as="form" onSubmit={handleOnSubmit}>
						<Input
							name="name"
							label="Nome"
							type="text"
							placeholder="Maria João"
							required
						/>
						<Input
							name="email"
							type="email"
							label="Email"
							placeholder="exemplo@email.pt"
							required
						/>
						<TextArea
							name="message"
							label="Mensagem"
							placeholder="Deixa a tua mensagem"
							required
						/>
						{message && (
							<Text style={[message.state, 'eventLocation']}>
								{message.body}
							</Text>
						)}
						<SubmitButton size="big" type="submit" loading={loading}>
							Enviar
						</SubmitButton>
					</FormCard>
				</InnerWrapper>
			</Wrapper>
		</Section>
	)
}

const query = graphql`
	query ContactInfo {
		strapi {
			contactInfo {
				address
				phone
			}
		}
	}
`

export default ContactUsBlock
