import { Link, graphql, useStaticQuery } from 'gatsby'
import { BooksBlockQuery } from 'graphqlTypes'
import React from 'react'

import BookCard from 'components/BookCard'
import SectionHeader from 'components/SectionHeader'
import Text from 'components/Text'

import { Section } from '../ArticleBlock/ArticleBlock.styles'
import { Book, BooksList } from './BooksBlock.styles'

interface BooksBlockProps {
	title: string
}

const BooksBlock: React.FC<BooksBlockProps> = ({ title }) => {
	const data = useStaticQuery(query) as NoUndefinedField<BooksBlockQuery>

	return (
		<Section width="padded">
			<SectionHeader title={title} />
			<BooksList role="list">
				{data.strapi.books.map((book, index) => (
					<Book key={index}>
						<Link to={`/books#${book.slug}`}>
							<BookCard name={book.title} image={book.bookCover} />
							<br />
							<Text style="dashLink">Saber mais</Text>
						</Link>
					</Book>
				))}
			</BooksList>
		</Section>
	)
}

const query = graphql`
	query BooksBlock {
		strapi {
			books {
				description
				title
				slug
				bookCover {
					...Image
				}
			}
		}
	}
`

export default BooksBlock
