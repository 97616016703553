import React from 'react'

import DynamicSEO from 'components/DynamicSEO'
import Footer from 'components/Footer'
import NavigationMenu from 'components/NavigationMenu'
import Wave from 'components/Wave'

import { Main } from './PageWrapper.styles'

const PageWrapper: React.FC = ({ children }) => {
	return (
		<>
			<DynamicSEO />
			<Wave />
			<Main id="main">
				<NavigationMenu />
				{children}
			</Main>
			<Footer />
		</>
	)
}

export default PageWrapper
