import { PageProps } from 'gatsby'
import ArticlePage from 'page-layout/ArticlePage'
import NotFoundPage from 'pages/404'
import { parse } from 'query-string'
import React from 'react'
import { Provider, createClient, gql, useQuery } from 'urql'

import Text from 'components/Text'

import { PreviewBar } from './Preview.styles'

const client = createClient({
	url: `${process.env.GATSBY_API_URL}/graphql`,
})

const query = gql`
	query ArticlePreview($slug: String) {
		articles(where: { slug_eq: $slug }, publicationState: PREVIEW) {
			title
			text
			slug
			background {
				...PreviewImage
			}
		}
	}

	fragment PreviewImage on UploadFile {
		alternativeText
		caption
		url
	}
`

interface PreviewViewProps {
	slug?: string
}

const PreviewView = ({ slug }: PreviewViewProps) => {
	const [{ data, fetching, error }] = useQuery({
		query,
		variables: { slug },
	})

	if (fetching) {
		return null
	}

	if (error || !data) {
		return <NotFoundPage error />
	}

	return <ArticlePage data={{ __typename: 'Query', strapi: data }} />
}

type PreviewProps = PageProps

const Preview = (props: PreviewProps) => {
	const search = parse(props.location.search)
	const slug = Array.isArray(search.slug)
		? search.slug[0]
		: search.slug || undefined

	return (
		<>
			<PreviewBar>
				<Text style={['eventLocation', 'white']}>
					O contéudo desta página não está publico, é apenas uma
					pré-visualização
				</Text>
			</PreviewBar>
			<Provider value={client}>
				<PreviewView slug={slug} />
			</Provider>
		</>
	)
}

export default Preview
