import {
	AboutMePageQuery,
	ArticlesIndexQuery,
	ContactUsPageQuery,
	HomePageQuery,
} from 'graphqlTypes'
import React from 'react'

import ArticleBlock from './ArticleBlock'
import BooksBlock from './BooksBlock'
import CircularTextActionBlock from './CircularTextActionBlock'
import ContactUsBlock from './ContactUsBlock'
import EventsBlock from './EventsBlock'
import QuoteBlock from './QuoteBlock'

type Blocks =
	| NoUndefinedField<AboutMePageQuery>['strapi']['aboutMePage']['content'][number]
	| NoUndefinedField<ArticlesIndexQuery>['strapi']['articlePage']['content'][number]
	| NoUndefinedField<ContactUsPageQuery>['strapi']['contactUsPage']['content'][number]
	| NoUndefinedField<HomePageQuery>['strapi']['homePage']['content'][number]
	| NoUndefinedField<AboutMePageQuery>['strapi']['aboutMePage']['content'][number]
	| null

const renderContent = (block: Blocks) => {
	if (block === null || !block.__typename || !block.id) {
		return null
	}

	const key = `${block.__typename}${block.id}`

	switch (block.__typename) {
		case 'strapi_ComponentContentBlocksQuote': {
			return <QuoteBlock key={key}>{block.text}</QuoteBlock>
		}
		case 'strapi_ComponentContentBlocksContactForm': {
			return (
				<ContactUsBlock
					key={key}
					title={block.title}
					description={block.description}
				/>
			)
		}
		case 'strapi_ComponentContentBlocksBookCarousel': {
			return <BooksBlock key={key} title={block.title} />
		}
		case 'strapi_ComponentContentBlocksArticleCarousel': {
			return <ArticleBlock key={key} title={block.title} />
		}
		case 'strapi_ComponentContentBlocksCircularBlock': {
			return (
				<CircularTextActionBlock
					key={key}
					title={block.title}
					content={block.description}
					image={block.image}
					mirroredImage={block.imagePosition === 'right'}
					button={block.button || undefined}
				/>
			)
		}
		case 'strapi_ComponentContentBlocksEvents': {
			return <EventsBlock key={key} title={block.title} />
		}
	}
}

interface EditorialProps {
	content: Blocks[]
}

const Editorial = ({ content }: EditorialProps) => {
	return <>{content.map(renderContent)}</>
}

export default Editorial
