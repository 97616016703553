import { styled } from '@linaria/react'

import BaseSection from 'components/Section'

export const Section = styled(BaseSection)`
	width: auto;
`

export const Wrapper = styled.div`
	padding: 21px 0 0 64px;
	position: relative;
`

export const Icon = styled.img`
	position: absolute;
	top: 0;
	left: 0;

	width: 40px;
	height: 40px;
	filter: invert(10%) sepia(59%) saturate(16%) hue-rotate(321deg)
		brightness(103%) contrast(83%);
`
