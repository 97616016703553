import { useLocation } from '@reach/router'
import BaseImage from 'images/4.jpeg'
import React from 'react'
import { Helmet } from 'react-helmet'

interface SEOProps {
	title?: string
	description?: string
	imageUrl?: string
}

const DEFAULT_TITLE = 'Diana Gaspar'
const DEFAULT_DESCRIPTION =
	'Se a vida te dá incertezas, vive-as com curiosidade.'
const SITE_URL = 'https://dianagaspar.pt'

const SEO: React.FC<SEOProps> = (props) => {
	const { title, description, imageUrl } = props

	const preventiveTitle = title || DEFAULT_TITLE
	const preventiveDescription = description || DEFAULT_DESCRIPTION
	const preventiveImageUrl = imageUrl || BaseImage

	const location = useLocation()
	const url = `${SITE_URL}${location.pathname}`

	return (
		<Helmet
			titleTemplate="%s | Diana Gaspar"
			defaultTitle={DEFAULT_TITLE}
			defer={false}
		>
			<html lang="pt" itemScope itemType="http://schema.org/WebPage" />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="pt_pt"></meta>

			<meta property="og:url" content={url} />
			<link rel="canonical" href={url} />

			<meta name="theme-color" content="#ffffff" />

			{/* Title */}
			<meta name="og:title" property="og:title" content={preventiveTitle} />
			<meta content={preventiveTitle} property="og:site_name"></meta>
			<meta content={preventiveTitle} name="twitter:title"></meta>
			{title && <title>{title}</title>}

			{/* Description */}
			<meta
				name="og:description"
				property="og:description"
				content={preventiveDescription}
			/>
			<meta name="twitter:description" content={preventiveDescription}></meta>
			<meta
				name="description"
				content={preventiveDescription}
				itemProp="description"
			/>

			{/* TODO */}
			<meta content={preventiveImageUrl} itemProp="image" />
			<meta name="twitter:card" content="summary_large_image"></meta>
			<meta
				name="og:image"
				property="og:image"
				content={preventiveImageUrl}
			></meta>
			<meta name="twitter:image" content={preventiveImageUrl}></meta>
		</Helmet>
	)
}

export default SEO
