import { css, cx } from '@linaria/core'

import { NavigationBar } from 'components/NavigationMenu/NavigationMenu.styles'

const common = css`
	& + & {
		margin-top: 20px;
	}

	@media only screen and (min-width: 768px) {
		& + & {
			margin-top: 130px;
		}

		${NavigationBar} + & {
			margin-top: 50px;
		}
	}
`

export const fullWidthSection = cx(
	common,
	css`
		width: 100%;
	`
)

export const paddedWidthSection = cx(
	common,
	css`
		margin-left: auto;
		margin-right: auto;

		width: 100%;
		max-width: 1440px;

		padding: 40px max(24px, min(7%, 75px)) 0;
	`
)
