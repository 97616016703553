import { Link, graphql, useStaticQuery } from 'gatsby'
import { ArticleBlockQuery } from 'graphqlTypes'
import React from 'react'

import ArticleCard from 'components/ArticleCard'
import SectionHeader from 'components/SectionHeader'

import {
	Card,
	CardList,
	MobileActionWrapper,
	Section,
	SeeMoreLink,
} from './ArticleBlock.styles'

interface ArticleBlockProps {
	title: string
	excludeSlug?: string
}

const ArticleBlock: React.FC<ArticleBlockProps> = (props) => {
	const { title, excludeSlug = '' } = props

	const {
		strapi: { articles },
	} = useStaticQuery(query) as NoUndefinedField<ArticleBlockQuery>

	const action = (
		<SeeMoreLink element={Link} style="actionText" to="/articles/">
			VER MAIS
		</SeeMoreLink>
	)

	const filteredArticles = articles
		.filter(({ slug }) => slug !== excludeSlug)
		.slice(0, 3)

	return (
		<Section>
			<SectionHeader title={title} action={action} />
			<CardList role="list">
				{filteredArticles.map(
					({ slug, title, background, description, published_at }) => (
						<Card key={slug}>
							<Link to={`/articles/${slug}`}>
								<ArticleCard
									title={title}
									date={published_at}
									description={description}
									image={background}
									authorName="Diana"
								/>
							</Link>
						</Card>
					)
				)}
			</CardList>
			<MobileActionWrapper>{action}</MobileActionWrapper>
		</Section>
	)
}

const query = graphql`
	query ArticleBlock {
		strapi {
			articles(sort: "created_at:desc", limit: 4) {
				title
				slug
				description
				published_at
				background {
					url
				}
			}
		}
	}
`

export default ArticleBlock
