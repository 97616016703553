import { cx } from '@linaria/core'
import React from 'react'

import * as textStyles from './Text.styles'

type Styles = keyof typeof textStyles

interface TextProps<T extends React.ElementType> {
	style?: Styles | Styles[]
	element?: T
	className?: string
}

const Text = React.forwardRef<Element>(function Text<
	T extends React.ElementType
>(
	props: Omit<React.ComponentPropsWithoutRef<T>, 'style'> & TextProps<T>,
	ref: React.ForwardedRef<Element>
) {
	const {
		className,
		element = 'span',
		style = 'paragraph',
		children,
		...otherProps
	} = props

	const stylesArray = Array.isArray(style) ? style : [style]

	return React.createElement(
		element,
		{
			className: cx(...stylesArray.map((item) => textStyles[item]), className),
			ref,
			...otherProps,
		},
		children
	)
}) as <T extends React.ElementType>(
	props: Omit<React.ComponentProps<T>, 'style'> & TextProps<T>
) => React.ReactElement

export default Text
