import { styled } from '@linaria/react'
import React from 'react'

import Text from 'components/Text'

import { Wrapper } from './SectionHeader.styles'

interface SectionHeaderProps {
	className?: string
	title: string
	action?: React.ReactNode
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
	className,
	title,
	action,
}) => (
	<Wrapper className={className}>
		<Text element="h1" style="heading">
			{title}
		</Text>
		{action}
	</Wrapper>
)

export default styled(SectionHeader)``
