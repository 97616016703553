import { Link, graphql, useStaticQuery } from 'gatsby'
import { ArticlesIndexQuery } from 'graphqlTypes'
import NotFoundPage from 'pages/404'
import React from 'react'

import ArticleCard from 'components/ArticleCard'
import Editorial from 'components/Editorial'
import HalfImageBlock from 'components/Editorial/HalfImageBlock'
import PageWrapper from 'components/PageWrapper'
import Section from 'components/Section'

import { Item, List } from './ArticlesIndex.styles'

const ArticlesIndex = () => {
	const { strapi } = useStaticQuery(
		query
	) as NoUndefinedField<ArticlesIndexQuery>

	const { articlePage, articles } = strapi

	if (!articlePage) {
		return <NotFoundPage error />
	}

	const { header, content } = articlePage

	return (
		<PageWrapper>
			<HalfImageBlock
				title={header.title}
				image={header.background}
				additionalWords={header.relatedWords.map(({ text }) => text)}
			/>
			<Editorial content={content} />
			<Section>
				<List role="list">
					{articles.map(
						({ title, slug, description, published_at, background }) => (
							<Item>
								<Link to={`/articles/${slug}`}>
									<ArticleCard
										title={title}
										date={published_at}
										description={description}
										image={background}
										authorName="Diana"
									/>
								</Link>
							</Item>
						)
					)}
				</List>
			</Section>
		</PageWrapper>
	)
}

const query = graphql`
	query ArticlesIndex {
		strapi {
			articlePage {
				content {
					__typename
					... on strapi_ComponentContentBlocksArticleCarousel {
						id
						title
					}
					... on strapi_ComponentContentBlocksBookCarousel {
						id
						title
					}
					... on strapi_ComponentContentBlocksContactForm {
						id
						description
						title
					}
					... on strapi_ComponentContentBlocksCircularBlock {
						id
						description
						image {
							...Image
						}
						imagePosition
						title
						button
					}
					... on strapi_ComponentContentBlocksQuote {
						id
						text
					}
					... on strapi_ComponentContentBlocksEvents {
						id
						title
					}
				}
				header {
					title
					relatedWords {
						text
					}
					background {
						...Image
					}
				}
			}

			articles(sort: "created_at:desc", publicationState: LIVE) {
				title
				slug
				description
				published_at
				background {
					...Image
				}
			}
		}
	}
`

export default ArticlesIndex
