import { styled } from '@linaria/react'

export const List = styled.ul`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(260px, 400px));
	justify-content: center;
	gap: 32px;
`

export const Item = styled.li``
