import { styled } from '@linaria/react'
import React from 'react'

interface ButtonProps extends React.ComponentProps<'button'> {
	size?: 'default' | 'big'
}

const Button = styled.button<ButtonProps>`
	min-width: 150px;

	font-family: 'Mulish', sans-serif;
	font-size: 1.8rem;
	font-weight: 400;
	color: var(--color-dark-charcoal);

	line-height: 1.5;

	appearance: none;
	line-height: 1.2222222;
	border: 1px solid transparent;
	text-align: center;
	position: relative;

	border-radius: 10000px;

	background: var(--color-magic-mint);
	overflow: hidden;
	cursor: pointer;

	padding: ${({ size }) => {
		if (size === 'big') {
			return '12px 18px'
		}

		return '8px 18px'
	}};

	:disabled {
		opacity: 0.8;
	}
`

Button.defaultProps = {
	size: 'default',
}

export default Button
