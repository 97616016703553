import { styled } from '@linaria/react'

export const FooterWrapper = styled.footer`
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 130px;
	border-top: 4px solid var(--color-deep-aquamarine);
	padding: 70px 100px;
`

export const SocialIcon = styled.img`
	width: 32px;
	height: 32px;
	filter: invert(10%) sepia(59%) saturate(16%) hue-rotate(321deg)
		brightness(103%) contrast(83%);
`

export const SocialIconList = styled.ul`
	display: flex;
	gap: 32px;
	flex-shrink: 0;
`
