import { styled } from '@linaria/react'

import BaseButton from 'components/Button'
import Image from 'components/Image'
import Text from 'components/Text'

export const Wrapper = styled.article`
	display: grid;
	gap: 24px;
	grid-template-areas:
		'image title'
		'image description'
		'image button';

	@media only screen and (max-width: 767px) {
		grid-template-areas:
			'title'
			'description'
			'image'
			'button';
	}
`

export const BookImage = styled(Image)`
	aspect-ratio: 0.66;
	width: 290px;

	grid-area: image;
	justify-self: center;
`

export const Title = styled(Text)`
	grid-area: title;
`

export const TextWrapper = styled.div`
	grid-area: description;
`

export const Button = styled(BaseButton)`
	grid-area: button;
	justify-self: start;
	align-self: start;

	@media only screen and (max-width: 767px) {
		justify-self: center;
		width: 290px;
	}
`
