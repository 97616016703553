import { cx } from '@linaria/core'
import { styled } from '@linaria/react'
import React from 'react'

import { TextStyles } from 'components/Text'

import { TextArea as BaseTextArea, Label } from './TextArea.styles'

interface TextAreaProps extends React.ComponentProps<'textarea'> {
	label: string
}

const TextArea: React.FC<TextAreaProps> = ({
	className,
	label,
	...TextAreaProps
}) => (
	<Label className={cx(className, TextStyles.inputLabel)}>
		{label}
		<BaseTextArea className={TextStyles.inputText} {...TextAreaProps} />
	</Label>
)

export default styled(TextArea)``
