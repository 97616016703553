import { styled } from '@linaria/react'
import React from 'react'

import Button from './Button'

const Spinner = styled.span`
	@keyframes load8 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 0.15em solid var(--color-dark-charcoal-20);
	border-right: 0.15em solid var(--color-dark-charcoal-20);
	border-bottom: 0.15em solid var(--color-dark-charcoal-20);
	border-left: 0.15em solid currentColor;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
	display: inline-block;

	background: var(--color-magic-mint);
	transition: all 0.5s ease-in-out;

	&,
	&:after {
		border-radius: 50%;
		width: 1.5em;
		height: 1.5em;
	}

	&.hidden {
		opacity: 0;
		box-shadow: 0 0 0 0 var(--color-magic-mint);
	}

	&.visible {
		opacity: 1;
		box-shadow: 0 0 0 200px var(--color-magic-mint);
	}
`

const BaseButton = styled(Button)`
	${Spinner} {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
`

interface LoadingButtonProps
	extends React.ComponentPropsWithoutRef<typeof Button> {
	loading?: boolean
}

const LoadingButton = ({ loading, children, ...props }: LoadingButtonProps) => {
	return (
		<BaseButton disabled={loading || props.disabled} {...props}>
			{children}
			<Spinner className={loading ? 'visible' : 'hidden'} />
		</BaseButton>
	)
}

export default styled(LoadingButton)``
