import { styled } from '@linaria/react'

export const PreviewBar = styled.aside`
	height: 40px;
	background: var(--color-ocean-green);
	position: sticky;
	top: 0;

	display: flex;
	justify-content: center;
	align-items: center;
`
