import { isAfter } from 'date-fns'
import { graphql, useStaticQuery } from 'gatsby'
import { EventsBlockQuery } from 'graphqlTypes'
import React from 'react'

import EventCard from 'components/EventCard'
import SectionHeader from 'components/SectionHeader'

import { Event, EventList, Section } from './EventsBlock.styles'

interface Event {
	location: string
	title: string
	time: string
}

interface EventBlockProps {
	title: string
}

const EventsBlock: React.FC<EventBlockProps> = ({ title }) => {
	const {
		strapi: { events },
	} = useStaticQuery(query) as NoUndefinedField<EventsBlockQuery>

	const currentEvents = events
		.filter(({ date }) => isAfter(new Date(date), new Date()))
		.slice(0, 6)

	if (currentEvents.length === 0) {
		return null
	}

	return (
		<Section>
			<SectionHeader title="Eventos">{title}</SectionHeader>
			<EventList role="list">
				{currentEvents.map((event) => (
					<Event>
						<EventCard
							title={event.name}
							location={event.location}
							time={event.date}
						/>
					</Event>
				))}
			</EventList>
		</Section>
	)
}

const query = graphql`
	query EventsBlock {
		strapi {
			events(sort: "date:asc", limit: 20) {
				name
				date
				location
			}
		}
	}
`

export default EventsBlock
