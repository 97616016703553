import { cx } from '@linaria/core'
import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'

import Text, { TextStyles } from 'components/Text'

import {
	List,
	MenuToggle,
	NavigationBar,
	Signature,
	Wrapper,
	stickyMenu,
} from './NavigationMenu.styles'

interface NavigationLink {
	to: string
}

const NavigationLink: React.FC<NavigationLink> = ({ children, to }) => (
	<li>
		<Text
			style="header"
			element={Link}
			to={to}
			activeClassName={TextStyles.headerActive}
			partiallyActive
		>
			{children}
		</Text>
	</li>
)

const NavigationMenu = () => {
	const [menuOpen, setMenuOpen] = useState(false)
	const [isSticky, setSticky] = useState(false)
	const headerRef = useRef<HTMLElement>(null)

	useEffect(() => {
		if (!headerRef.current) {
			return
		}

		const observer = new IntersectionObserver(
			([e]) => {
				setSticky(e.intersectionRatio < 1)
			},
			{ threshold: [1] }
		)

		observer.observe(headerRef.current)

		return () => {
			observer.disconnect()
		}
	}, [headerRef.current])

	useEffect(() => {
		if (menuOpen) {
			document.querySelector('body')?.classList.add('block-scroll')
		} else {
			document.querySelector('body')?.classList.remove('block-scroll')
		}
	}, [menuOpen])

	return (
		<NavigationBar
			className={cx(isSticky && stickyMenu)}
			data-opened={menuOpen}
			ref={headerRef}
		>
			<Wrapper>
				<Link to="/">
					<Signature />
					<span className="sr-only">Página principal</span>
				</Link>
				<div>
					<MenuToggle
						type="checkbox"
						name="open-menu"
						checked={menuOpen}
						onChange={() => {
							setMenuOpen(!menuOpen)
						}}
					/>
					<List role="list">
						<NavigationLink to="/about-me/">Sobre Mim</NavigationLink>
						<NavigationLink to="/articles/">Artigos</NavigationLink>
						<NavigationLink to="/books/">Livros</NavigationLink>
						<NavigationLink to="/contact-us/">Contactos</NavigationLink>
					</List>
				</div>
			</Wrapper>
		</NavigationBar>
	)
}

export default NavigationMenu
