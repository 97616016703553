import { css } from '@linaria/core'

const colors = css`
	:global() {
		:root {
			--color-yellow-green: #90b534;
			--color-yellow-green-30: rgba(144, 181, 52, 0.3);

			--color-magic-mint: #b7e4c7;
			--color-ocean-green: #52b788;
			--color-deep-aquamarine: #40916c;

			--color-white: #ffffff;
			--color-anti-flash-white: #f1f2f2;
			--color-gainsboro: #dddddd
			--color-light-silver: #d8d8d8;
			--color-quick-silver: #a4a4a4;
			--color-spanish-grey: #959595;
			--color-dark-grey: #6e6e6e;
			--color-dark-charcoal: #2f2f2f;
			--color-dark-charcoal-20: rgba(47, 47, 47, 0.39);
			--color-raisin-black: #1f2322;
			--color-chinese-black: #161616;
			--color-black: #000000;
		}
	}
`

export default colors
