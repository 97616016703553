import { styled } from '@linaria/react'

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding-bottom: 18px;
	border-bottom: 1px solid var(--color-quick-silver);
`
