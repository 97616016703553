import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { SeoQuery } from 'graphqlTypes'
import React from 'react'

import SEO from '../SEO'

const DynamicSEO = () => {
	const { pathname } = useLocation()
	const { strapi } = useStaticQuery<SeoQuery>(query)

	const seo = strapi?.seos?.find((seo) => {
		return pathname === seo?.url
	})

	if (!seo) {
		return null
	}

	return (
		<SEO
			title={seo.title || undefined}
			description={seo.description}
			imageUrl={seo.image?.url || undefined}
		/>
	)
}

export const query = graphql`
	query SEO {
		strapi {
			seos {
				description
				jsonld
				title
				url
				image {
					url
					alternativeText
					caption
				}
			}
		}
	}
`

export default DynamicSEO
