import { styled } from '@linaria/react'
import { ImageFragment } from 'graphqlTypes'
import React from 'react'

const BasePicture = styled.picture`
	background: var(--color-gainsboro);
`

const BaseImage = styled.img`
	height: 100%;
	width: 100%;

	object-fit: cover;
	object-position: center;

	:not([src]) {
		visibility: hidden;
	}
`

interface ImageProps extends React.ComponentProps<'picture'> {
	image: ImageFragment
}

const Image = ({ className, image, ...props }: ImageProps) => (
	<BasePicture className={className} {...props}>
		<source src={image?.url} />
		<BaseImage
			src={image?.url}
			alt={image?.alternativeText || image?.caption || undefined}
		/>
	</BasePicture>
)

export default Image
