import { styled } from '@linaria/react'

import { LoadingButton } from 'components/Button'
import Card from 'components/Card'
import BaseSection from 'components/Section'
import SectionHeader from 'components/SectionHeader'
import { TextStyles } from 'components/Text'
import { TextArea } from 'components/TextArea/TextArea.styles'

export const Section = styled(BaseSection)`
	display: flex;
	flex-direction: column;

	${SectionHeader} {
		margin-bottom: 50px;
	}
`

export const InnerWrapper = styled.div`
	display: flex;
	flex: 1;
`

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 40px;

	& ${InnerWrapper}:nth-child(2) {
		justify-content: flex-end;
	}

	@media only screen and (max-width: 768px) {
		flex-direction: column;

		& ${InnerWrapper}:nth-child(2) {
			justify-content: center;
		}
	}
`

export const FormCard = styled(Card)`
	display: flex;
	flex-direction: column;
	gap: 28px;
	padding: 40px;
	margin-bottom: 10px;

	width: 100%;
	max-width: 600px;

	${TextArea} {
		height: 200px;
		resize: none;
	}
`

export const Icon = styled.img`
	margin-top: 2px;

	width: 24px;
	filter: invert(10%) sepia(59%) saturate(16%) hue-rotate(321deg)
		brightness(103%) contrast(83%);
`

export const ContactWrapper = styled.div`
	display: flex;
	align-items: flex-start;

	& + & {
		margin-top: 30px;
	}

	.${TextStyles.paragraph} {
		margin-left: 8px;
	}
`

export const ContactBlockWrapper = styled.aside`
	margin-top: 60px;
`

export const SubmitButton = styled(LoadingButton)`
	width: 150px;

	@media only screen and (max-width: 768px) {
		width: auto;
	}
`
